import React from 'react';
import { Layout } from '../components/layout';
import { categories } from '../components/constants';

export default function Portfolio() {
    const metaProps = {
        title: 'Portfolio',
    };

    return (
        <Layout metaProps={metaProps}>
            <section className="section-30 section-sm-top-35 section-xl-top-120 bg-image bg-image-4">
                <div className="shell shell-fluid">
                    <div className="range range-condensed">
                        <div className="cell-xs-12 cell-xl-preffix-1 cell-xl-11">
                            <p className="h6 text-italic font-serif text-regular">Najlepsze momenty naszego salonu</p>
                            <p className="h1">Portfolio</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-lighter">
                <div className="shell shell-wide">
                    <div className="range range-condensed range-md-middle">
                        <div className="cell-md-6 cell-xl-preffix-1 cell-xl-4">
                            <div className="cell-box">
                                <div className="section-60">
                                    <h2>O nas</h2>
                                    <div className="offset-top-20 offset-sm-top-30">
                                        <div className="inset-lg-right-30 inset-xl-right-55">
                                            <p>
                                                Nowoczesny salon urody “Beauty-Spot” w samym sercu Łodzi, w którym oferujemy profesjonalne zabiegi z zakresu Fryzjerstwa, koloryzacji włosów, depilacji, manicure oraz pedicure, męski pedicure oraz manicure, przedłużanie rzęs, laminowanie brwi oraz rzęs, regulacja brwi, męska regulacja brwi, henna brwi, henna pudrowa brwi, makijaż permanentny brwi, ust i powiek, masaż ciała!
                                            </p>
                                        </div>
                                    </div>
                                    <p className="offset-top-20">
                                        Beauty-spot został stworzony z myślą o osobach, które chcą dbać o swój wygląd w przyjaznej atmosferze, w wygodnej lokalizacji. Pracujemy wyłącznie na profesjonalnych, sprawdzonych oraz skutecznych w działaniu i najwyższej jakości kosmetykach.
                                    </p>
                                    <div className="offset-top-30">
                                        <div className="group-lg">
                                            <a href="/book" className="btn btn-half-baked">Zarezerwuj wizytę</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cell-md-6 cell-xl-preffix-1 height-fill">
                            <article className="box-custom box-custom-link box-custom-right">
                                <a href={`/portfolio/${categories[0].slug}`}>
                                    <figure>
                                        <img src={`/images/${categories[0].items[0]}`} alt="" width="480px" />
                                    </figure>
                                </a>
                                <div className="box-body">
                                    <div className="box-content">
                                        <div className="box-link-minimal">
                                            <div className="box-link-header">
                                                <h4><a href={`/portfolio/${categories[0].slug}`}>{categories[0].caption}</a></h4>
                                                <div className="divider divider-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article className="box-custom box-custom-primary box-custom-link box-custom-left">
                                <a href={`/portfolio/${categories[1].slug}`}>
                                    <figure>
                                        <img src={`/images/${categories[1].items[0]}`} alt="" width="480px" />
                                    </figure>
                                </a>
                                <div className="box-body">
                                    <div className="box-content">
                                        <div className="box-link-minimal box-link-primary-filled">
                                            <div className="box-link-header">
                                                <h4><a href={`/portfolio/${categories[1].slug}`}>{categories[1].caption}</a></h4>
                                                <div className="divider divider-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="cell-md-6">
                            <article className="box-custom box-custom-half-baked box-custom-link box-custom-right">
                                <a href={`/portfolio/${categories[2].slug}`}>
                                    <figure>
                                        <img src={`/images/${categories[2].items[18]}`} alt="" width="480px" />
                                    </figure>
                                </a>
                                <div className="box-body">
                                    <div className="box-content">
                                        <div className="box-link-minimal box-link-half-baked-filled">
                                            <div className="box-link-header">
                                                <h4><a href={`/portfolio/${categories[2].slug}`}>{categories[2].caption}</a></h4>
                                                <div className="divider divider-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="cell-md-6">
                            <article className="box-custom box-custom-link box-custom-left box-custom-md-right">
                                <a href={`/portfolio/${categories[5].slug}`}>
                                    <figure><img src={`/images/${categories[5].items[18]}`} alt="" width="480px" />
                                    </figure>
                                </a>
                                <div className="box-body">
                                    <div className="box-content">
                                        <div className="box-link-minimal box-link-half-baked-colored">
                                            <div className="box-link-header">
                                                <h4><a href={`/portfolio/${categories[5].slug}`}>{categories[5].caption}</a></h4>
                                                <div className="divider divider-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="cell-md-6">
                            <article className="box-custom box-custom-link box-custom-left box-custom-md-right">
                                <a href={`/portfolio/${categories[3].slug}`}>
                                    <figure><img src={`/images/${categories[3].items[6]}`} alt="" width="480px" />
                                    </figure>
                                </a>
                                <div className="box-body">
                                    <div className="box-content">
                                        <div className="box-link-minimal box-link-half-baked-colored">
                                            <div className="box-link-header">
                                                <h4><a href={`/portfolio/${categories[3].slug}`}>{categories[3].caption}</a></h4>
                                                <div className="divider divider-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        
                        <div className="cell-md-6">
                            <article className="box-custom box-custom-half-baked box-custom-link box-custom-right">
                                <a href={`/portfolio/${categories[6].slug}`}>
                                    <figure>
                                        <img src={`/images/${categories[6].items[1]}`} alt="" width="480px" />
                                    </figure>
                                </a>
                                <div className="box-body">
                                    <div className="box-content">
                                        <div className="box-link-minimal box-link-half-baked-filled">
                                            <div className="box-link-header">
                                                <h4><a href={`/portfolio/${categories[6].slug}`}>{categories[6].caption}</a></h4>
                                                <div className="divider divider-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};
