exports.categories = [
    {
        caption: 'Strzyżenie',
        slug: 'strzyzenie',
        items: [
            // 'strzyzenie/134163557_202789551496930_6372338214874898145_o.jpg',
            'strzyzenie/IMG_3592.JPG',
            'strzyzenie/IMG_3606.JPG',
            'strzyzenie/IMG_3635.JPG',
            'strzyzenie/IMG_3638.JPG',
            'strzyzenie/IMG_3657.JPG',
            // 'strzyzenie/IMG_3669.JPG',
        ],
    },
    {
        caption: 'Koloryzacja',
        slug: 'koloryzacja',
        items: [
            // 'koloryzacja/129317574_184255850016967_5576397492632404500_o.jpg',
            // 'koloryzacja/130460606_187737123002173_6570759156144588698_o.jpg',
            // 'koloryzacja/136480277_207962614312957_8448191714212130507_o.jpg',
            'koloryzacja/IMG_3601.JPG',
            'koloryzacja/IMG_3608.JPG',
            'koloryzacja/IMG_3611.JPG',
            'koloryzacja/IMG_3612.JPG',
            'koloryzacja/IMG_3632.JPG',
            'koloryzacja/IMG_3643.JPG',
            'koloryzacja/IMG_3644.JPG',
            'koloryzacja/IMG_3645.JPG',
            'koloryzacja/IMG_3649.JPG',
            'koloryzacja/IMG_3664.JPG',
            'koloryzacja/IMG_3666.JPG',
            'koloryzacja/IMG_3671.JPG',
        ],
    },
    {
        caption: 'Stylizacja rzęs',
        slug: 'rzesy',
        items: [
            'rzesy/IMG_3593.jpeg',
            'rzesy/IMG_3595.jpeg',
            'rzesy/IMG_3596.jpeg',
            'rzesy/IMG_3597.jpeg',
            'rzesy/IMG_3599.jpeg',
            'rzesy/IMG_3600.jpeg',
            'rzesy/IMG_3602.jpeg',
            'rzesy/IMG_3603.jpeg',
            'rzesy/IMG_3604.jpeg',
            'rzesy/IMG_3607.jpeg',
            'rzesy/IMG_3610.jpeg',
            'rzesy/IMG_3613.jpeg',
            'rzesy/IMG_3615.jpeg',
            'rzesy/IMG_3624.jpeg',
            'rzesy/IMG_3626.jpeg',
            'rzesy/IMG_3630.jpeg',
            'rzesy/IMG_3631.jpeg',
            'rzesy/IMG_3634.jpeg',
            'rzesy/IMG_3636.jpeg',
            'rzesy/IMG_3639.jpeg',
            'rzesy/IMG_3642.jpeg',
            'rzesy/IMG_3647.jpeg',
            'rzesy/IMG_3652.jpeg',
        ],
    },
    {
        caption: 'Brwi',
        slug: 'brwi',
        items: [
            'brwi/IMG_3594.JPG',
            'brwi/IMG_3598.JPG',
            'brwi/IMG_3605.JPG',
            'brwi/IMG_3609.JPG',
            'brwi/IMG_3614.JPG',
            'brwi/IMG_3627.JPG',
            'brwi/IMG_3628.JPG',
            'brwi/IMG_3633.JPG',
            'brwi/IMG_3640.JPG',
            'brwi/IMG_3641.JPG',
            'brwi/IMG_3651.JPG',
            'brwi/IMG_3658.JPG',
            'brwi/IMG_3659.JPG',
            'brwi/IMG_3660.JPG',
            'brwi/IMG_3663.JPG',
            'brwi/IMG_3667.JPG',
            'brwi/IMG_3670.JPG',
            // 'brwi/photo_2021-03-16 19.10.07.jpeg',
            // 'brwi/photo_2021-03-16 19.10.15.jpeg',
        ],
    },
    {
        caption: 'Depilacja',
        slug: 'depilacja',
        items: [
            'depilacja/IMG_4034.JPG',
            'depilacja/IMG_4042 (1).PNG',
        ],
    },
    {
        caption: 'Manicure / pedicure',
        slug: 'manicure-pedicure',
        items: [
            'manicure-pedicure/IMG_3616.jpeg',
            'manicure-pedicure/IMG_3617.jpeg',
            'manicure-pedicure/IMG_3618.jpeg',
            'manicure-pedicure/IMG_3619.jpeg',
            'manicure-pedicure/IMG_3620.jpeg',
            'manicure-pedicure/IMG_3621.jpeg',
            'manicure-pedicure/IMG_3622.jpeg',
            'manicure-pedicure/IMG_3623.jpeg',
            'manicure-pedicure/IMG_3625.jpeg',
            'manicure-pedicure/IMG_3629.jpeg',
            'manicure-pedicure/IMG_3637.jpeg',
            'manicure-pedicure/IMG_3646.jpeg',
            'manicure-pedicure/IMG_3648.jpeg',
            'manicure-pedicure/IMG_3650.jpeg',
            'manicure-pedicure/IMG_3653.jpeg',
            'manicure-pedicure/IMG_3654.jpeg',
            'manicure-pedicure/IMG_3655.jpeg',
            'manicure-pedicure/IMG_3661.jpeg',
            'manicure-pedicure/IMG_3665.jpeg',
            'manicure-pedicure/IMG_4013.jpeg',
            'manicure-pedicure/IMG_4017.jpeg',
            'manicure-pedicure/IMG_4025.jpeg',
            'manicure-pedicure/IMG_4039.jpeg',
            'manicure-pedicure/IMG_4040.jpeg',
            'manicure-pedicure/IMG_4041.jpeg',
        ],
    },
    // {
    //     caption: 'Makijaż',
    //     items: [
    //         '',
    //     ],
    // },
    {
        caption: 'Makijaż Permanentny',
        slug: 'makijaz',
        items: [
            'brwi/IMG_3656.JPG',
            'brwi/IMG_4016.PNG',
        ],
    },
];